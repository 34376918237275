import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '57e7c007-9bc6-4a23-93e5-80dc35f5839f',
  clientToken: 'pub9f4a958a9d87ce964606c0a1e671c282',
  site: 'datadoghq.com',
  service: 'web',
  env: window.qsiConfig.ENV,
  version: process.env.VERSION,
  trackUserInteractions: true,
});
